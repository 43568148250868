export const COMPANY_PHONE_NUMBER_NUMERIC = '6086183508';
export const COMPANY_PHONE_NUMBER_WITH_DASHES = '608-618-3508';
export const COMPANY_ADDRESS = '2890 Terra Ct';
export const COMPANY_ADDRESS_2 = 'Unit 15';
export const COMPANY_CITY = 'Sun Prairie';
export const COMPANY_STATE = 'WI';
export const COMPANY_ZIPCODE = '53590';
export const AFFORDABLE_RATES_TEXT = 'Affordable Rates';
export const RELIABLE_SERVICE_TEXT = 'Speedy & Reliable Services';
export const QUALITY_WORK_TEXT = 'Trusted Quality Work';
export const MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT = 'Our Services';
export const MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT = 'Preventative Maintenance';
export const MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT = 'Repairs';
export const MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT = 'Emergency Roadside Assistance & Computer Diagnostics';
export const COMPANY_SLOGAN_TEXT = '“Your One Stop Shop on Wheels”';
export const FOOTER_COVERAGE_AREA_PREFIX_TEXT = 'Shop & Mobile Repair & Fleet Services in';
export const GOOGLE_ANALYTICS_ID = 'G-TL94JXDGKP';
export const SERVICE_TYPES_TEXT = 'Trucks, Trailers, Material Handling Equipment'