import fleetServiceMajorTruckRepairImg from '../extra-images/truck-repair-fleet-services-major-repairs.gif';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import CallUsScheduleCallBackBtnComponent from './CallUsScheduleCallBackBtnComponent';
import SignageAndTextComponent from './SignageAndTextComponent';
import { Helmet } from 'react-helmet-async';
import { MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT, GOOGLE_ANALYTICS_ID, SERVICE_TYPES_TEXT } from '../Constants';

export default function MajorRepairServices() {
  return (
    <>
    <Helmet>
        <title>Truck Repair | Trailer Repair | Forklift Repair | We Come To You</title>
        <meta name="description" content="Let our expert mechanics perform minor & major repair services on your fleet of trucks, trailers, material handling equipment, and more." />
        <meta property="og:description" content="Minor & Major Truck Repair Services" />
        <meta property="og:image" content="https://danefleetservices.com/static/media/truck-repair-fleet-services-major-repairs.ed3639b8cd54a1c80afd.gif"/>
        <meta property="og:url" content="https://danefleetservices.com/truck-fleet-services-major-truck-repair" />
        <meta property="og:site_name" content="Truck Repair | Trailer Repair | Forklift Repair | We Come To You" />
        <meta property="og:locale" content="en_US" />

      {/* Global site tag (gtag.js) - Google Analytics  */}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${GOOGLE_ANALYTICS_ID}");
        `}
      </script>
    </Helmet>

    <div className="truck-repair-fleet-services-main-wrapper">
        <header id="truck-repair-fleet-services-header" className="truck-repair-fleet-services-header-one">
            <HeaderComponent />
            <div className="truck-repair-fleet-services-header-navigation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-repair-fleet-services' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-preventative-maintenance' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-major-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-emergency-roadside-assistance-diagnostics-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="truck-repair-fleet-services-subheader">
            <span className="truck-repair-fleet-services-dark-transparent"></span>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Repair Services</h1>
                        <h3 className="truck-repair-fleet-services-white-text">For your <strong className="truck-repair-fleet-services-white-text">{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                        <p>Our mechanic experts repair just about anything from trucks to trailers to material handing equipment and more. If you are having issues, give us a call and let one of our expert diesel mechanics diagnose and fix the issues so you can get back on the road.</p>
                        <div className="clearfix"></div>
                        <div className="truck-repair-fleet-services-breadcrumb">
                            <ul>
                                <li>Let Our Expert Mechanics Perform Repairs For Your Fleet</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="truck-repair-fleet-services-main-content truck-repair-fleet-services-main-content-padding">
            <div className="truck-repair-fleet-services-main-section truck-repair-fleet-services-service-textfull">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="truck-repair-fleet-services-fancy-title">
                                <h2>Our Repair Services</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="truck-repair-fleet-services-service-text">
                                <h5>Repair Services</h5>
                                <h3>For your <strong>{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                                <p>Is your engine not operating correctly? If something has failed on your truck, trailer, material handling equipment, or anything else, give us a call. We will get one of our experienced mechanics to come out and diagnose your issue and get you back up and running in no time.</p>
                                <ul>
                                    <li>DOT Inspections</li>
                                    <li>Preventive Maintenance</li>
                                    <li>Brakes</li>
                                    <li>Batteries</li>
                                    <li>Cooling System Repairs</li>
                                    <li>Welding</li>
                                    <li>Hydraulic Repair</li>
                                    <li>Hoses & Belts</li>
                                    <li>Computer Diagnosis</li>
                                    <li>Trailer Repair</li>
                                    <li>Forklift Repair</li>
                                    <li>Telehandler Repair</li>
                                    <li>Fuel System Repairs</li>
                                    <li>Steering & Suspension</li>
                                    <li>And Much Much More..</li>
                                </ul>
                                <CallUsScheduleCallBackBtnComponent />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <figure className="truck-repair-fleet-services-service-thumb"><img src={fleetServiceMajorTruckRepairImg} alt="Truck Fleet Maintenance and Major Repair Services Mechanic" />
                            </figure>
                        </div>

                    </div>
                </div>
            </div>
            <SignageAndTextComponent />
        </div>
    <FooterComponent />
    <div className="clearfix"></div>
    </div>
    </>
  );
}