import "./App.css";
import { BrowserRouter as Router, Routes, Route }
    from "react-router-dom";
import TruckRepairFleetServices from "./components/TruckRepairFleetServices";
import PreventativeMaintenanceServices from "./components/PreventativeMaintenanceServices";
import MajorRepairServices from "./components/MajorRepairServices";
import RoadsideDiagnosticsRepairServices from "./components/RoadsideDiagnosticsRepairServices";
import { HelmetProvider } from "react-helmet-async";
import TruckFleetMaintenanceRepairServices from "./components/TruckFleetMaintenanceRepairServices";
import Careers from "./components/Careers";

function App() {
  return (
    <HelmetProvider>
    <Router>
      <Routes>
        <Route path="/" element={<TruckRepairFleetServices />}/>
        <Route path="/truck-repair-fleet-services" element={<TruckRepairFleetServices />}/>
        <Route path="/truck-fleet-services-preventative-maintenance" element={<PreventativeMaintenanceServices />}/>
        <Route path="/truck-fleet-services-major-truck-repair" element={<MajorRepairServices/>}/>
        <Route path="/truck-fleet-services-emergency-roadside-assistance-diagnostics-truck-repair" element={<RoadsideDiagnosticsRepairServices/>}/>
        <Route path="/truck-repair-fleet-services-by-location" element={<TruckFleetMaintenanceRepairServices/>}/>
        <Route path="/careers" element={<Careers/>}/>
      </Routes>
    </Router>
    </HelmetProvider>
  );
}
  
export default App;