import * as React from "react";
import { AFFORDABLE_RATES_TEXT, RELIABLE_SERVICE_TEXT, QUALITY_WORK_TEXT } from "../Constants";

export default class SignageAndTextComponent extends React.Component <{}> {
  render() {
    return (
        <>
            <div className="truck-repair-fleet-services-main-section truck-repair-fleet-services-services-grid-full">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="truck-repair-fleet-services-fancy-title">
                            </div>
                            <div className="truck-repair-fleet-services-services truck-repair-fleet-services-services-grid truck-repair-fleet-services-services-margin">
                                <ul className="row">
                                    <li className="col-md-4">
                                        <i className="icon truck-repair-fleet-services-business"></i>
                                        <h5>{AFFORDABLE_RATES_TEXT}</h5>
                                    </li>
                                    <li className="col-md-4">
                                        <i className="icon truck-repair-fleet-services-man"></i>
                                        <h5>{RELIABLE_SERVICE_TEXT}</h5>
                                    </li>
                                    <li className="col-md-4">
                                        <i className="icon truck-repair-fleet-services-people-1"></i>
                                        <h5>{QUALITY_WORK_TEXT}</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
  }
}