import * as React from "react";
import { ScheduleCallBackForm } from "./ScheduleCallBackFormComponent";
import { COMPANY_ADDRESS, COMPANY_ADDRESS_2, COMPANY_CITY, COMPANY_PHONE_NUMBER_WITH_DASHES, COMPANY_STATE, COMPANY_ZIPCODE, FOOTER_COVERAGE_AREA_PREFIX_TEXT } from "../Constants";
import fsiAndAffiliateLogo from '../images/FSIPGpowered.jpg';


export default class HeaderComponent extends React.Component <{}> {
  render() {
    return (
        <>
            <footer id="truck-repair-fleet-services-footer" className="truck-repair-fleet-services-footer-one">
            <div className="truck-repair-fleet-services-footer-services-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="truck-repair-fleet-services-footer-services">
                                <ul className="row">
                                    <li className="col-md-4">
                                        <div className="truck-repair-fleet-services-footer-services-text">
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="truck-repair-fleet-services-footer-widget">
                <div className="container">
                    <div style={{paddingBottom: '50px'}}><img src={fsiAndAffiliateLogo} /></div>
                    <div className="row">
                        <aside className="col-md-4 widget widget_contact_us">
                            <h2 className="truck-repair-fleet-services-footer-title">Service Phone Number:</h2>
                            <ul>
                                <li><i className="icon truck-repair-fleet-services-telephone-1"></i> <strong>Mobile Truck Repair & Fleet Services:</strong><br /><br /><a href={"tel:" + COMPANY_PHONE_NUMBER_WITH_DASHES}>{COMPANY_PHONE_NUMBER_WITH_DASHES}</a></li>
                                <li><i className="icon truck-repair-fleet-services-telephone-1"></i> <strong>Mobile Truck Roadside Assistance & Diagnostics:</strong><br /><br /><a href={"tel:" + COMPANY_PHONE_NUMBER_WITH_DASHES}>{COMPANY_PHONE_NUMBER_WITH_DASHES}</a></li>
                            </ul>
                            <ul className="truck-repair-fleet-services-shadule-list">
                                <li><i className="fa fa-clock-o"></i> Service Hours</li>
                                <li><i className="fa fa-clock-o"></i> Monday - Friday 8am - 5pm</li>
                                <li><i className="fa fa-clock-o"></i> Emergency Roadside Assistance</li>
                                <li><i className="fa fa-clock-o"></i> Our <strong style={{color: "black"}}>shop & mobile services</strong> at your convenience!</li>
                                <li><i className="fa fa-clock-o"></i> We have a mobile unit and we can come to you!</li>
                            </ul>
                            <h2 className="truck-repair-fleet-services-footer-title">Our Address:</h2>
                            <ul className="address">
                                <li><i className="icon truck-repair-fleet-services-map-location"></i> <p><strong>{COMPANY_ADDRESS} {COMPANY_ADDRESS_2} <br />{COMPANY_CITY}, {COMPANY_STATE} {COMPANY_ZIPCODE}</strong></p></li>
                            </ul>
                            <h2 className="truck-repair-fleet-services-footer-title">Careers with us:</h2>
                            <ul className="address">
                                <li><p><a href="/careers">Our Current Openings</a></p></li>
                            </ul>
                        </aside>
                        <aside className="col-md-8 widget widget_services">
                            <h2 className="truck-repair-fleet-services-footer-title">Mobile Truck Repair & Fleet Service Coverage Area:</h2>
                            <ul>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Dane%20County%20Wisconsin'><strong>We service all of Dane County Wisconsin</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Bellville%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Belleville, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Black%20Earth%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Black Earth, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Blue%20Mound%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Blue Mounds, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Cambridge%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Cambridge, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Cottage%20Grove%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Cottage Grove, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Cross%20Plains%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Cross Plains, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Dane%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Dane, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=De%20Forest%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} De Forest, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Deerfield%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Deerfield, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Madison%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Madison, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Marshall%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Marshall, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Mazomanie%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Mazomanie, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Mc%20Farland%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Mc Farland, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Middleton%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Middleton, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Morrisonville%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Morrisonville, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Mount%20Horeb%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Mount Horeb, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Oregon%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Oregon, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Stoughton%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Stoughton, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Sun%20Prairie%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Sun Prairie, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Verona%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Verona, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Waunakee%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Waunakee, WI</strong></a></li>
                                <li><a href='/truck-repair-fleet-services-by-location?truck-repair-services-location=Windsor%2C%20WI'><strong>{FOOTER_COVERAGE_AREA_PREFIX_TEXT} Windsor, WI</strong></a></li>
                            </ul>
                        </aside>
                    </div>
                </div>
            </div>
            <div className="truck-repair-fleet-services-footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p><strong>Dane Fleet Services Inc.</strong> <i className="fa fa-copyright"></i>Copyright 2024. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            </footer>
        </>
    );
  }
}