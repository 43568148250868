import fleetServiceMechanicLocaleImg from '../extra-images/truck-repair-fleet-services-mechanic.gif';
import HeaderComponent from './HeaderComponent';
import CallUsScheduleCallBackBtnComponent from './CallUsScheduleCallBackBtnComponent';
import FooterComponent from './FooterComponent';
import SignageAndTextComponent from './SignageAndTextComponent';
import { Helmet } from 'react-helmet-async';
import { MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT, GOOGLE_ANALYTICS_ID, SERVICE_TYPES_TEXT } from '../Constants';


export default function TruckFleetMaintenanceRepairServices() {
  // URL Parameters
  const queryParameters = new URLSearchParams(window.location.search);
  const fleetMaintenanceLocation: string | null = queryParameters.get("truck-repair-services-location");

  let encodedFleetMaintenanceLocation = null;
  let canonicalUrl = "";
  if (fleetMaintenanceLocation) {
    encodedFleetMaintenanceLocation = encodeURIComponent(fleetMaintenanceLocation);
    canonicalUrl = "https://danefleetservices.com/truck-repair-fleet-services-by-location?truck-repair-services-location=" + encodedFleetMaintenanceLocation;
  }
  let inLineFleetTitleText: string = '';
  let inLineFleetDescriptionText: string = '';
  if (fleetMaintenanceLocation) {
    inLineFleetTitleText = ' - ' + fleetMaintenanceLocation;
    inLineFleetDescriptionText = ' in ' +  fleetMaintenanceLocation;
  }

  return (
    <>
    <Helmet>
    <title>{'Mobile Truck and Trailer Repair & Fleet Services - ' + fleetMaintenanceLocation + '- Serving All of Dane County'}</title>
        <meta name="description" content={"Let our expert mobile diesel mechanics come out to you to perform truck and trailer repairs and fleet services. We save your business time & money and save you from the inconveniences of a stationary shop. We are based in Sun Prairie, WI, next door to Madison, WI and serve " + fleetMaintenanceLocation + "."} />
        <meta property="og:description" content={"Your Expert Truck and Trailer Diesel Mechanic" + inLineFleetDescriptionText} />
        <meta property="og:image" content="https://danefleetservices.com/static/media/our-fleet-services.4706f1777a3312effcf6.gif"/>
        <meta property="og:url" content="https://danefleetservices.com" />
        <meta property="og:site_name" content={"Mobile Truck and Trailer Repair & Fleet Services - " + fleetMaintenanceLocation + " - Serving All of Dane County"} />
        <meta property="og:locale" content="en_US" />
        <link rel="canonical" href={canonicalUrl} />

        {/* Global site tag (gtag.js) - Google Analytics  */}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${GOOGLE_ANALYTICS_ID}");
        `}
      </script>
    </Helmet>
    <div className="truck-repair-fleet-services-main-wrapper">
        <header id="truck-repair-fleet-services-header" className="truck-repair-fleet-services-header-one">
            <HeaderComponent />
            <div className="truck-repair-fleet-services-header-navigation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-repair-fleet-services' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-preventative-maintenance' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-major-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-emergency-roadside-assistance-diagnostics-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="truck-repair-fleet-services-subheader">
            <span className="truck-repair-fleet-services-dark-transparent"></span>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Truck Repair & Fleet Services{inLineFleetTitleText}</h1>
                        <h3 className="truck-repair-fleet-services-white-text">For your <strong className="truck-repair-fleet-services-white-text">{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                        <p>We provide the highest level of service to our customers and their trucks, trailers, material handling equipment, and other diesel equipment at competitive pricing. Give us a call today to see how our truck repair & fleet services can save your company time, money, and resources.</p>
                        <div className="clearfix"></div>
                        <div className="truck-repair-fleet-services-breadcrumb">
                            <ul>
                                <li>Our Truck Repair & Fleet Services{inLineFleetDescriptionText}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="truck-repair-fleet-services-main-content truck-repair-fleet-services-main-content-padding">
            <div className="truck-repair-fleet-services-main-section truck-repair-fleet-services-service-textfull">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="truck-repair-fleet-services-fancy-title">
                                <h2>Our Truck Repair & Fleet Services{inLineFleetDescriptionText}</h2>
                                <h3>For your <strong>{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="truck-repair-fleet-services-service-text">
                            <h3>1. Comprehensive Diagnostics:</h3>
    <ul>
        <li>On-site diagnostics using advanced tools to accurately identify issues with your truck.</li>
        <li>Detailed explanation of the problems and recommended solutions.</li>
    </ul>

    <br/>
    <h3>2. Routine Maintenance:</h3>
    <ul>
        <li>Oil changes, filter replacements, and greasing to keep your truck running smoothly.</li>
        <li>Courtesy inspections to ensure overall truck health.</li>
    </ul>

    <br/>
    <h3>3. Brake Services:</h3>
    <ul>
        <li>Inspection and replacement of brake pads, rotors, and calipers to maintain optimal braking performance.</li>
        <li>Brake fluid replacement and system bleeding to ensure safety and reliability.</li>
    </ul>

    <br/>
    <h3>4. Engine Repairs:</h3>
    <ul>
        <li>Minor and major engine repairs, including spark plug replacements, belt changes, and gasket repairs.</li>
        <li>Engine performance tuning and adjustments to enhance efficiency and power.</li>
    </ul>

    <br/>
    <h3>5. Electrical System Repairs:</h3>
    <ul>
        <li>Battery testing and replacement, alternator repairs, and starter motor diagnostics.</li>
        <li>Troubleshooting and fixing electrical issues, including lights, fuses, and wiring.</li>
    </ul>

    <br/>
    <h3>6. Cooling System Services:</h3>
    <ul>
        <li>Radiator and coolant hose inspection and replacement to prevent overheating.</li>
        <li>Coolant flushes and thermostat replacements to maintain proper engine temperature.</li>
    </ul>

    <br/>
    <h3>7. Transmission Services:</h3>
    <ul>
        <li>Transmission fluid changes and filter replacements to ensure smooth shifting.</li>
        <li>Minor transmission repairs and adjustments on-site for immediate solutions.</li>
    </ul>

    <br/>
    <h3>8. Suspension and Steering Repairs:</h3>
    <ul>
        <li>Inspection and replacement of shocks, struts, and other suspension components for a smoother ride.</li>
        <li>Steering system diagnostics and repairs to enhance handling and safety.</li>
    </ul>

    <br/>
    <h3>9. Fuel System Services:</h3>
    <ul>
        <li>Fuel filter replacements and fuel pump inspections to maintain proper fuel flow.</li>
        <li>Fuel injector cleaning and repairs to ensure efficient combustion.</li>
    </ul>

    <br/>
    <h3>10. Emergency Roadside Assistance:</h3>
    <ul>
        <li>Quick response for non-functional brake chamber, wheel seal leaks, coolant leaks, dead batteries, broken belts, and more.</li>
        <li>Minor on-site repairs to get you back on the road without needing a tow.</li>
    </ul>

    <br/>

    <h3>11. Trailer Repairs:</h3>
    <ul>
        <li>Quick response for wheel seal leaks, dead or broken lights, panel leaks, lift gate repairs, roll up door repair and replacement, and more.</li>
        <li>On-site repairs to get your trailer back on the road.</li>
    </ul>

    <br/>
    <h2>Why Choose Our Truck & Trailer Repair Services:</h2>
    <ul>
        <li><strong>Convenience</strong>: We come to you, eliminating the need to arrange transportation or wait at a repair shop.</li>
        <li><strong>Experienced Technicians</strong>: Our diesel mechanics are highly skilled, capable of handling a wide range of repairs.</li>
        <li><strong>Transparent Services & Pricing</strong>: Honest and upfront services & pricing with no hidden fees. You’ll know the cost before we start any work.</li>
        <li><strong>Quality Parts</strong>: We use high-quality parts to ensure the longevity and reliability of our repairs.</li>
        <li><strong>Customer Satisfaction</strong>: We are committed to providing excellent service and ensuring your complete satisfaction.</li>
        <li><strong>Flexible Scheduling</strong>: We offer flexible appointment times to fit your busy schedule, including evenings and weekends.</li>
    </ul>

    <br/>
    <br/>
    <p>At Dane Fleet Services, we strive to make truck maintenance and repairs as hassle-free as possible. Our truck & trailer repair services bring the expertise of a professional repair shop directly to you. Contact us today to schedule an appointment and experience the convenience and quality of our mobile services.</p>

                                <CallUsScheduleCallBackBtnComponent />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <figure className="truck-repair-fleet-services-service-thumb"><img src={fleetServiceMechanicLocaleImg} alt={"Truck Fleet Maintenance and Repair Services Mechanic" + inLineFleetDescriptionText} />
                            </figure>
                        </div>

                    </div>
                </div>
            </div>
            <SignageAndTextComponent />
        </div>
    <FooterComponent />
    <div className="clearfix"></div>
    </div>
    </>
  );
}
