import * as React from "react";
import { scrollToScheduleCallBack } from "../common/ts/scrollToScheduleCallBack";
import { COMPANY_PHONE_NUMBER_WITH_DASHES } from "../Constants";

export default class CallUsScheduleCallBackBtnComponent extends React.Component <{}> {
  render() {
    return (
        <><a href={"tel:" + COMPANY_PHONE_NUMBER_WITH_DASHES} className="truck-repair-fleet-services-modern-btn truck-repair-fleet-services-bgcolor">CALL NOW</a>
    </>
    );
  }
}