import maintenanceMechanicImg from '../extra-images/truck-repair-fleet-services-roadside-assistance.gif';
import HeaderComponent from './HeaderComponent';
import CallUsScheduleCallBackBtnComponent from './CallUsScheduleCallBackBtnComponent';
import FooterComponent from './FooterComponent';
import SignageAndTextComponent from './SignageAndTextComponent';
import { Helmet } from 'react-helmet-async';
import { MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT, GOOGLE_ANALYTICS_ID, SERVICE_TYPES_TEXT } from '../Constants';

export default function RoadsideDiagnosticsRepairServices() {
  return (
    <>
    <Helmet>
        <title>Truck & Trailer Emergency Roadside Assistance Diagnostics & Repair Services | We Come To You | Sun Prairie Madison WI</title>
        <meta name="description" content="Had a breakdown and in need of emergency roadside assistance? Let our expert mechanics come out to you to perform roadside diagnostics and repair services and get you back on the road." />
        <meta property="og:description" content="Truck Emergency Roadside Assistance Diagnostics & Repair Services" />
        <meta property="og:image" content="https://danefleetservices.com/static/media/truck-repair-fleet-services-roadside-assistance.1d8d1f9b4ce2d6498e91.gif"/>
        <meta property="og:url" content="https://danefleetservices.com/truck-fleet-services-emergency-roadside-assistance-diagnostics-truck-repair" />
        <meta property="og:site_name" content="Truck Emergency Roadside Assistance Diagnostics & Repair Services - We Come To You - Sun Prairie Madison WI" />
        <meta property="og:locale" content="en_US" />

        {/* Global site tag (gtag.js) - Google Analytics  */}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${GOOGLE_ANALYTICS_ID}");
        `}
      </script>
    </Helmet>
    <div className="truck-repair-fleet-services-main-wrapper">
        <header id="truck-repair-fleet-services-header" className="truck-repair-fleet-services-header-one">
            <HeaderComponent />
            <div className="truck-repair-fleet-services-header-navigation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-repair-fleet-services' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-preventative-maintenance' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-major-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-emergency-roadside-assistance-diagnostics-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="truck-repair-fleet-services-subheader">
            <span className="truck-repair-fleet-services-dark-transparent"></span>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Emergency Roadside Assistance & Computer Diagnostics</h1>
                        <h3 className="truck-repair-fleet-services-white-text">For your <strong className="truck-repair-fleet-services-white-text">{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                        <p>In an emergency where your truck, trailer, diesel equipment has broken down on the side of the road or at the customer's site in the Dane County WI area and in need of emergency assistance, give us a call and our experienced mechanics will come to your rescue and get you back up and running through diagnostics and repairs.</p>
                        <div className="clearfix"></div>
                        <div className="truck-repair-fleet-services-breadcrumb">
                            <ul>
                                <li>We come to your rescue with our Emergency Roadside Assistance & Computer Diagnostic Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="truck-repair-fleet-services-main-content truck-repair-fleet-services-main-content-padding">
            <div className="truck-repair-fleet-services-main-section truck-repair-fleet-services-service-textfull">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="truck-repair-fleet-services-fancy-title">
                                <h2>Our 24hr Emergency Roadside Assistance & Computer Diagnostics</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="truck-repair-fleet-services-service-text">
                                <h5>Emergency Roadside Assistance & Computer Diagnostic Services</h5>
                                <h3>For your <strong>{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                                <p>Have a check engine light? Our experienced mechanics and their state of the art mobile service trucks can come out and diagnose your problems right at your site.</p>
                                <h5>Emergency Roadside Assistance Repair Services</h5>
                                <p>If you are having issues on the side of the road or at a customer's site due to a parts failure, Our experienced mechanics and their state of the art mobile service trucks can come out and diagnose your problems, perform repairs, and get you running again.</p>
                                <h5>Let us get you back up and running. Call us now!</h5>
                                <CallUsScheduleCallBackBtnComponent />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <figure className="truck-repair-fleet-services-service-thumb"><img src={maintenanceMechanicImg} alt="Truck Fleet Roadside Repair Services Mechanic" />
                            </figure>
                        </div>

                    </div>
                </div>
            </div>
            <SignageAndTextComponent />
        </div>
    <FooterComponent />
    <div className="clearfix"></div>
    </div>
    </>
  );
}