import fleetServiceRepairMechanicImg from '../extra-images/truck-repair-fleet-services-mechanic.gif';
import HeaderComponent from './HeaderComponent';
import CallUsScheduleCallBackBtnComponent from './CallUsScheduleCallBackBtnComponent';
import FooterComponent from './FooterComponent';
import SignageAndTextComponent from './SignageAndTextComponent';
import { Helmet } from 'react-helmet-async';
import { MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT } from '../Constants';

export default function Home() {
  return (
    <>
    <Helmet>
    <title>Careers with Dane Fleet Services | Make a change and work for someone who cares about you</title>
        <meta name="description" content="Come work for us here at Dane Fleet Services. We value all of our employees and give them competitve pay, bonuses, career growth, and a good work life balance." />
        <meta property="og:locale" content="en_US" />
    </Helmet>
    <div className="truck-repair-fleet-services-main-wrapper">
        <header id="truck-repair-fleet-services-header" className="truck-repair-fleet-services-header-one">
            <HeaderComponent />
            <div className="truck-repair-fleet-services-header-navigation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-repair-fleet-services' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-preventative-maintenance' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-major-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-emergency-roadside-assistance-diagnostics-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="truck-repair-fleet-services-fancy-title">
                    <h2>Job Openings</h2>
                </div>
            </div>
            <div className="col-md-6">
                <div className="truck-repair-fleet-services-service-text">
                    <h5>No current openings</h5>
                </div>
            </div>
            <div className="col-md-6">
                <img src={fleetServiceRepairMechanicImg} alt="Truck Repair and Fleet Services Mechanic" />
            </div>
        </div>
    </div>
<FooterComponent />
<div className="clearfix"></div>
    </>
  );
}