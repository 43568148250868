import truckRepairFleetServiceMaintenanceImg from '../extra-images/truck-repair-fleet-services-preventative-maintenance.gif';
import HeaderComponent from './HeaderComponent';
import CallUsScheduleCallBackBtnComponent from './CallUsScheduleCallBackBtnComponent';
import FooterComponent from './FooterComponent';
import SignageAndTextComponent from './SignageAndTextComponent';
import { Helmet } from 'react-helmet-async';
import { MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT, GOOGLE_ANALYTICS_ID, SERVICE_TYPES_TEXT } from '../Constants';

export default function PreventativeMaintenanceServices() {
  return (
    <>
    <Helmet>
        <title>Truck Fleet Services | Oil Changes | DOT Inspections | Preventative Maintenance | We Come To You | Sun Prairie Madison WI</title>
        <meta name="description" content="Let our expert technicians perform preventative maintenance services on your fleet of trucks. From oil changes to DOT inspections. We do it all to keep your fleet on the road with maximum uptime." />
        <meta property="og:description" content="Truck Fleet Services - Oil Changes, DOT Inspections, Preventative Maintenance" />
        <meta property="og:image" content="https://danefleetservices.com/static/media/truck-repair-fleet-services-preventative-maintenance.8d1728990da72a5348f6.gif"/>
        <meta property="og:url" content="https://danefleetservices.com/truck-fleet-services-preventative-maintenance" />
        <meta property="og:site_name" content="Truck Fleet Services | Oil Changes | DOT Inspections | Preventative Maintenance | We Come To You | Sun Prairie Madison WI" />
        <meta property="og:locale" content="en_US" />

        {/* Global site tag (gtag.js) - Google Analytics  */}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${GOOGLE_ANALYTICS_ID}");
        `}
      </script>
    </Helmet>
    <div className="truck-repair-fleet-services-main-wrapper">
        <header id="truck-repair-fleet-services-header" className="truck-repair-fleet-services-header-one">
            <HeaderComponent />
            <div className="truck-repair-fleet-services-header-navigation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-repair-fleet-services' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-preventative-maintenance' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-major-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-emergency-roadside-assistance-diagnostics-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="truck-repair-fleet-services-subheader">
            <span className="truck-repair-fleet-services-dark-transparent"></span>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <h1>Preventative Maintenance Services</h1>
                    <h3 className="truck-repair-fleet-services-white-text">For your <strong className="truck-repair-fleet-services-white-text">{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                        <p>We follow the manufacturer's maintenance service schedule to ensure that your fleet has the maximum uptime for your business. The best part about us is that <strong style={{textTransform: 'uppercase', color: 'white'}}>we come to you</strong> so we can save your business time to focus on what it does best.</p>
                        <div className="clearfix"></div>
                        <div className="truck-repair-fleet-services-breadcrumb">
                            <ul>
                                <li>Our Preventative Maintenance Services For Your Fleet</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="truck-repair-fleet-services-main-content truck-repair-fleet-services-main-content-padding">
            <div className="truck-repair-fleet-services-main-section truck-repair-fleet-services-service-textfull">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="truck-repair-fleet-services-fancy-title">
                                <h2>Our Preventative Maintenance Services</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="truck-repair-fleet-services-service-text">
                                <h5>Fleet Preventative Maintenance Services</h5>
                                <h3>For your <strong>{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                                <p>We follow the manufacturer's maintenance service schedule to ensure that your fleet has the maximum uptime for your business. The best part about us is that <strong style={{textTransform: 'uppercase'}}>we come to you</strong> so we can save your business money and give back time to you and your employees so everyone can focus on what they do best.</p>
                                <ul>
                                    <li>Oil Changes</li>
                                    <li>Brakes</li>
                                    <li>Full Tune-Ups</li>
                                    <li>Preventative Maintenance Inspections</li>
                                    <li>DOT Arrangements</li>
                                    <li>Air, Fuel and Lube Filters</li>
                                    <li>Fan Motors</li>
                                    <li>Air Conditioning</li>
                                    <li>Transmission Services</li>
                                    <li>Water Pumps</li>
                                    <li>Fuel Pumps</li>
                                    <li>Power Steering</li>
                                    <li>Alternators</li>
                                    <li>Batteries &amp; Cables</li>
                                    <li>Shocks &amp; Suspension</li>
                                    <li>Belts &amp; Hoses</li>
                                    <li>Preventative Maintenance</li>
                                    <li>Lift Gates</li>
                                    <li>Hydraulics</li>
                                    <li>And MORE!</li>
                                </ul>
                                <h5>Get Our Preventative Maintenance Services Now</h5>
                                <CallUsScheduleCallBackBtnComponent />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <figure className="truck-repair-fleet-services-service-thumb"><img src={truckRepairFleetServiceMaintenanceImg} alt="Truck Fleet Preventative Maintenance and Repair Services Mechanic" />
                            </figure>
                        </div>

                    </div>
                </div>
            </div>
            <SignageAndTextComponent />
        </div>
    <FooterComponent />
    <div className="clearfix"></div>
    </div>
    </>
  );
}