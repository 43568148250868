import * as React from "react";
import logo from '../images/dane-fleet-services-logo.jpg';
import { scrollToScheduleCallBack } from "../common/ts/scrollToScheduleCallBack";
import { COMPANY_PHONE_NUMBER_WITH_DASHES, COMPANY_SLOGAN_TEXT } from "../Constants";

export default class HeaderComponent extends React.Component <{}> {
  render() {
    return (
        <>
        <div className="truck-repair-fleet-services-top-strip">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="truck-repair-fleet-services-strip-info">
                            <li className="left"><strong style={{color: "white"}}>{COMPANY_SLOGAN_TEXT}</strong></li>
                            <li><i className="icon truck-repair-fleet-services-map-location"></i><strong style={{ color: 'white' }}>We Service All of Dane County</strong></li>
                            <li className="right"><span><i className="fa fa-phone" style={{ fontSize: '18px', color: 'white' }}></i> Call Now! <a href={"tel:" + COMPANY_PHONE_NUMBER_WITH_DASHES} style={{ color: 'white' }}>{COMPANY_PHONE_NUMBER_WITH_DASHES}</a></span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Navigation />
    </>
    );
  }
}

function Navigation() {
  return (
    <>
    <div className="truck-repair-fleet-services-main-header">
            <div className="container">
                <div className="row">
                    <aside className="col-md-3"><a href="/truck-repair-fleet-services" className="logo" style={{cursor: 'pointer'}}><img src={logo} alt="Dane Truck Fleet Maintenance and Repair Services Logo" /> </a></aside>
                    <aside className="col-md-5">
                        <div className="truck-repair-fleet-services-shadule">
                            <i className="fa fa-clock-o"></i>
                            <ul className="truck-repair-fleet-services-shadule-list">
                                <li>Service Hours</li>
                                <li>Monday - Friday 8am - 5pm</li>
                                <li>Emergency Roadside Assistance</li>
                                <li>Our <strong style={{color: "black"}}>shop & mobile services</strong> at your convenience!</li>
                                <li>We have a <strong style={{color: "black"}}>mobile</strong> unit and we can come to you!</li>
                            </ul>
                        </div>
                    </aside>
                    <aside className="col-md-4">
                        <a href={"tel:" + COMPANY_PHONE_NUMBER_WITH_DASHES} className="truck-repair-fleet-services-appointment-btn"><i className="icon truck-repair-fleet-services-telephone-1"></i> CALL US</a>
                    </aside>
                </div>
            </div>
        </div>
    </>
  )

}