import fleetServiceRepairMechanicImg from '../extra-images/truck-repair-fleet-services-mechanic.gif';
import HeaderComponent from './HeaderComponent';
import CallUsScheduleCallBackBtnComponent from './CallUsScheduleCallBackBtnComponent';
import FooterComponent from './FooterComponent';
import SignageAndTextComponent from './SignageAndTextComponent';
import { Helmet } from 'react-helmet-async';
import { MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT, MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT, GOOGLE_ANALYTICS_ID, SERVICE_TYPES_TEXT } from '../Constants';

export default function Home() {
  return (
    <>
    <Helmet>
    <title>Truck and Trailer Repair & Fleet Services - Madison WI</title>
        <meta name="description" content="We are your leading mobile truck and trailer repair & fleet services serving Madison, WI and the surrounding areas." />
        <meta property="og:description" content="Expert Mobile Mechanic Truck and Trailer Repair & Fleet Services" />
        <meta property="og:image" content="https://danefleetservices.com/static/media/our-fleet-services.4706f1777a3312effcf6.gif"/>
        <meta property="og:url" content="https://danefleetservices.com" />
        <meta property="og:site_name" content="Mobile Truck and Trailer Repair & Fleet Services - We Come To You - Madison WI" />
        <meta property="og:locale" content="en_US" />
        <link rel="canonical" href="https://danefleetservices.com" />

        {/* Global site tag (gtag.js) - Google Analytics  */}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${GOOGLE_ANALYTICS_ID}");
        `}
      </script>
    </Helmet>
    <div className="truck-repair-fleet-services-main-wrapper">
        <header id="truck-repair-fleet-services-header" className="truck-repair-fleet-services-header-one">
            <HeaderComponent />
            <div className="truck-repair-fleet-services-header-navigation">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-repair-fleet-services' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-preventative-maintenance' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_PREVENTATIVE_MAINTENANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-major-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_MAJOR_REPAIRS_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            <nav id="menu" className="menu navbar navbar-default">
                                <ul className="level-1 navbar-nav">
                                    <li><a href='/truck-fleet-services-emergency-roadside-assistance-diagnostics-truck-repair' className='menu'>{MENU_TRUCK_REPAIR_FLEET_SERVICES_ROADSIDE_ASSISTANCE_TEXT}</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="truck-repair-fleet-services-subheader">
            <span className="truck-repair-fleet-services-dark-transparent"></span>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Truck Repair & Fleet Services</h1>
                        <h3 className="truck-repair-fleet-services-white-text">For your <strong className="truck-repair-fleet-services-white-text">{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                        <p>At DFS, we bring expert diesel truck & trailer repair services and maintenance directly to your location. Our mechanic services are designed to provide you with the ultimate convenience, saving you time, money, and hassle. Whether it's in the parking lot, at a job site, or stranded on the road, our skilled technicians are ready to get your truck and trailer back on the road quickly and safely.</p>
                        <div className="clearfix"></div>
                        <div className="truck-repair-fleet-services-breadcrumb">
                            <ul>
                                <li>Our Truck Repair & Fleet Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="truck-repair-fleet-services-main-content truck-repair-fleet-services-main-content-padding">
            <div className="truck-repair-fleet-services-main-section truck-repair-fleet-services-service-textfull">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="truck-repair-fleet-services-fancy-title">
                                <h2>Our Services</h2>
                                <h3>For your <strong>{SERVICE_TYPES_TEXT}</strong> and more.</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="truck-repair-fleet-services-service-text">
    <h3>1. Comprehensive Diagnostics:</h3>
    <ul>
        <li>On-site diagnostics using advanced tools to accurately identify issues with your truck.</li>
        <li>Detailed explanation of the problems and recommended solutions.</li>
    </ul>

    <br/>
    <h3>2. Routine Maintenance:</h3>
    <ul>
        <li>Oil changes, filter replacements, and greasing to keep your truck running smoothly.</li>
        <li>Courtesy inspections to ensure overall truck health.</li>
    </ul>

    <br/>
    <h3>3. Brake Services:</h3>
    <ul>
        <li>Inspection and replacement of brake pads, rotors, and calipers to maintain optimal braking performance.</li>
        <li>Brake fluid replacement and system bleeding to ensure safety and reliability.</li>
    </ul>

    <br/>
    <h3>4. Engine Repairs:</h3>
    <ul>
        <li>Minor and major engine repairs, including spark plug replacements, belt changes, and gasket repairs.</li>
        <li>Engine performance tuning and adjustments to enhance efficiency and power.</li>
    </ul>

    <br/>
    <h3>5. Electrical System Repairs:</h3>
    <ul>
        <li>Battery testing and replacement, alternator repairs, and starter motor diagnostics.</li>
        <li>Troubleshooting and fixing electrical issues, including lights, fuses, and wiring.</li>
    </ul>

    <br/>
    <h3>6. Cooling System Services:</h3>
    <ul>
        <li>Radiator and coolant hose inspection and replacement to prevent overheating.</li>
        <li>Coolant flushes and thermostat replacements to maintain proper engine temperature.</li>
    </ul>

    <br/>
    <h3>7. Transmission Services:</h3>
    <ul>
        <li>Transmission fluid changes and filter replacements to ensure smooth shifting.</li>
        <li>Minor transmission repairs and adjustments on-site for immediate solutions.</li>
    </ul>

    <br/>
    <h3>8. Suspension and Steering Repairs:</h3>
    <ul>
        <li>Inspection and replacement of shocks, struts, and other suspension components for a smoother ride.</li>
        <li>Steering system diagnostics and repairs to enhance handling and safety.</li>
    </ul>

    <br/>
    <h3>9. Fuel System Services:</h3>
    <ul>
        <li>Fuel filter replacements and fuel pump inspections to maintain proper fuel flow.</li>
        <li>Fuel injector cleaning and repairs to ensure efficient combustion.</li>
    </ul>

    <br/>
    <h3>10. Emergency Roadside Assistance:</h3>
    <ul>
        <li>Quick response for non-functional brake chamber, wheel seal leaks, coolant leaks, dead batteries, broken belts, and more.</li>
        <li>Minor on-site repairs to get you back on the road without needing a tow.</li>
    </ul>

    <br/>

    <h3>11. Trailer Repairs:</h3>
    <ul>
        <li>Quick response for wheel seal leaks, dead or broken lights, panel leaks, lift gate repairs, roll up door repair and replacement, and more.</li>
        <li>On-site repairs to get your trailer back on the road.</li>
    </ul>

    <br/>
    <h2>Why Choose Our Truck & Trailer Repair Services:</h2>
    <ul>
        <li><strong>Convenience</strong>: We come to you, eliminating the need to arrange transportation or wait at a repair shop.</li>
        <li><strong>Experienced Technicians</strong>: Our diesel mechanics are highly skilled, capable of handling a wide range of repairs.</li>
        <li><strong>Transparent Services & Pricing</strong>: Honest and upfront services & pricing with no hidden fees. You’ll know the cost before we start any work.</li>
        <li><strong>Quality Parts</strong>: We use high-quality parts to ensure the longevity and reliability of our repairs.</li>
        <li><strong>Customer Satisfaction</strong>: We are committed to providing excellent service and ensuring your complete satisfaction.</li>
        <li><strong>Flexible Scheduling</strong>: We offer flexible appointment times to fit your busy schedule, including evenings and weekends.</li>
    </ul>

    <br/>
    <br/>
    <p>At Dane Fleet Services, we strive to make truck maintenance and repairs as hassle-free as possible. Our truck & trailer repair services bring the expertise of a professional repair shop directly to you. Contact us today to schedule an appointment and experience the convenience and quality of our mobile services.</p>


                                <CallUsScheduleCallBackBtnComponent />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={fleetServiceRepairMechanicImg} alt="Truck Repair and Fleet Services Mechanic" />
                        </div>
                    </div>
                </div>
            </div>
            <SignageAndTextComponent />
        </div>
    <FooterComponent />
    <div className="clearfix"></div>
    </div>
    </>
  );
}