
import App from './App';
import reportWebVitals from './reportWebVitals';

import './css/bootstrap.css';
import './css/color.css';
import './css/fancybox.css';
import './css/flaticon.css';
import './css/font-awesome.css';
import './css/responsive.css';
import './css/rtl.css';
import './css/slick-slider.css';
import './style.css';


import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
if (rootElement!.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
